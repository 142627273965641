import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../../components/sidebar/index.jsx";
import TopBar from "../../../components/topbar/index.jsx";
import { api, consultaEmpresas, CFeConsolidadoA } from "../../../services/api.jsx";
import Loader from "../../../components/loader/index.jsx";
import { AuthContext } from "../../../contexts/auth.jsx";
import { formatarCNPJ } from "../../../function/formatCNPJ.jsx";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { convertReal } from "../../../function/convertReal.jsx";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { convertDate } from "../../../function/convertDate.jsx";


const CFeConsolidado = () => {

    const { logout } = useContext(AuthContext);
    const Navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState([]);
    const [selectedEmpresa, setSelectedEmpresa] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [empresa, setEmpresa] = useState([]);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        else {
            Navigate("/login");
        }

        consultaEmpresasF().then(() => {
            setLoading(false);
        });

        const today = new Date();
        const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1);
        const defaultMonth = previousMonth.toISOString().substring(0, 7); // Formata como YYYY-MM
        setSelectedMonth(defaultMonth);

    }, []);

    const consultaEmpresasF = async () => {
        try {
            var empresa = await consultaEmpresas();
            setEmpresa(empresa.data);
        }
        catch (err) {
            if (err.empresa.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }

    }

    const handleGerarRelatorio = async (e) => {
        try {
            setLoading(true);
            setMsg('Gerando relatório')
            var response = await CFeConsolidadoA(selectedEmpresa, selectedMonth);
            setResponse(response.data);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    const handleSelectChange = (e) => {
        setSelectedEmpresa(e.target.value);
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleExport = () => {
        // Converte os dados para um formato compatível com Excel (array de arrays ou objetos)
        const worksheet = XLSX.utils.json_to_sheet(response);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Gera um buffer do arquivo e salva
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'dados.xlsx');
    };

    return (
        <>
            {(isLoading) &&
                <Loader msg={msg} />
            }
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xl-12 col-lg-7">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Relatório NFe PIS/COFINS Geral</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-7 col-sm-12">
                                                    <label htmlFor="selectCliente">Selecione a empresa</label>
                                                    <select
                                                        id="selectCliente"
                                                        className="form-control"
                                                        value={selectedEmpresa}
                                                        onChange={handleSelectChange}
                                                    >
                                                        <option value="">Selecione uma empresa</option>
                                                        {empresa.map((empresa, key) => (
                                                            <option key={key} value={empresa.str_cnpj}>
                                                                {empresa.str_razaosocial} - {formatarCNPJ(empresa.str_cnpj)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-3 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="selectMonth">Selecione o mês</label>
                                                        <input
                                                            type="month"
                                                            id="selectMonth"
                                                            className="form-control"
                                                            value={selectedMonth}
                                                            onChange={handleMonthChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-sm-12 d-grid">
                                                    <button onClick={(e) => handleGerarRelatorio()} type="button" className="btn btn-primary">Gerar Relatório</button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <button onClick={handleExport} type="button" className="btn btn-success">
                                                        <i className="fa-solid fa-table me-2"></i>Excel
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                    <table className="table table-bordered w-100">
                                                        <thead>
                                                            <tr>
                                                                <th>Nº Série SAT</th>
                                                                <th>Nº CF-e</th>
                                                                <th>Nº CF-e </th>
                                                                <th>Data</th>
                                                                <th>CFOP</th>
                                                                <th>Total CF-e </th>
                                                                <th>Base de Cálculo ICMS</th>
                                                                <th>Aliquota ICMS</th>
                                                                <th>Valor ICMS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {response.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td>{item.ide_nseriesat}</td>
                                                                    <td>{item.ide_ncfe}</td>
                                                                    <td>{item.ide_ncfe_cancelamento}</td>
                                                                    <td>{convertDate(item.ide_demi)}</td>
                                                                    <td>{item.det_prod_cfop}</td>
                                                                    <td>{convertReal(item.det_prod_vitem)}</td>
                                                                    <td>{convertReal(item.icms_base_calculo)}</td>
                                                                    <td>{convertReal(item.icms_picms)}</td>
                                                                    <td>{convertReal(item.icms_vicms)}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default CFeConsolidado;