
const Loader = ({ msg }) => {
    return (
        <>
            <div className='loading' style={{ textAlign: 'center' }}>
                <div
                    className="spinner-border"
                    style={{ width: "4rem", height: "4rem", color: "#3e3ca3" }}
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="ms-3" style={{ marginTop: "1rem", color: "#3e3ca3", fontSize: "1.2rem" }}>
                    {msg || "Aguarde..."}
                </p>
            </div>
        </>
    );
};

export default Loader;
