import React from 'react';
import ReactDOM from 'react-dom/client';
import Rotas from './rotas.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/default.css';
import './style.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Rotas />
);