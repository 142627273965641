import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../../components/sidebar/index.jsx";
import TopBar from "../../../components/topbar/index.jsx";
import { api, consultaHistorico } from "../../../services/api.jsx";
import Loader from "../../../components/loader/index.jsx";
import { AuthContext } from "../../../contexts/auth.jsx";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { convertDateTime } from "../../../function/convertDateTime.jsx";
import './style.css'


const HistoricoDownload = () => {

    const { logout } = useContext(AuthContext);
    const Navigate = useNavigate();

    const user = localStorage.getItem('user');
    const userObj = user ? JSON.parse(user) : null;
    const cnpj = userObj ? userObj.str_cnpj : null;

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        else {
            Navigate("/login");
        }


        const today = new Date();
        const previousMonth = new Date(today.getFullYear(), today.getMonth());
        const defaultMonth = previousMonth.toISOString().substring(0, 7); // Formata como YYYY-MM
        setSelectedMonth(defaultMonth);

        handleGerarRelatorio(defaultMonth);

    }, []);


    const handleGerarRelatorio = async (month) => {
        try {
            setLoading(true);
            setMsg('Gerando relatório')
            var response = await consultaHistorico(month);
            setResponse(response.data);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleExport = () => {
        // Converte os dados para um formato compatível com Excel (array de arrays ou objetos)
        const worksheet = XLSX.utils.json_to_sheet(response);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Gera um buffer do arquivo e salva
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'dados.xlsx');
    };

    const handleShowLog = (logString) => {

        // Parse o logString para um array
        let logs = [];
        try {
            logs = JSON.parse(logString);
        } catch (error) {
            logs = [];
        }

        console.log(logs);

        // Verifica se há itens no array
        if (logs.length > 0) {
            // Mostra os logs no SweetAlert2
            Swal.fire({
                title: "Detalhes do Log",
                html: `${logs
                    .map(
                        (log, index) =>
                            `<li class="list-group-item">${index + 1}. ${log}</li>`
                    )
                    .join("")
                    }`,
                icon: "info",
                width: '80%',
                confirmButtonText: "Fechar",
            });
        }
    };

    const [searchText, setSearchText] = useState("");

    // Função para filtrar registros com base no texto de pesquisa
    const filteredResponse = response.filter(item =>
        item.str_razaosocial.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            {(isLoading) &&
                <Loader msg={msg} />
            }
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xl-12 col-lg-7">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Histórico de download de arquivos</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="row justify-content-center">
                                                <div className="col-md-3 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="selectMonth">Selecione o mês</label>
                                                        <input
                                                            type="month"
                                                            id="selectMonth"
                                                            className="form-control"
                                                            value={selectedMonth}
                                                            onChange={handleMonthChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-sm-12 d-grid">
                                                    <button onClick={(e) => handleGerarRelatorio(selectedMonth)} type="button" className="btn btn-primary">Gerar Relatório</button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-2 d-grid gap-2">
                                                    <button onClick={handleExport} type="button" className="btn btn-success ">
                                                        <i className="fa-solid fa-table me-2"></i>Excel
                                                    </button>
                                                </div>
                                                <div className="col-10">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Pesquisar por empresa..."
                                                        value={searchText}
                                                        onChange={(e) => setSearchText(e.target.value)}
                                                    />
                                                </div>

                                                {/* Tabela com alertas */}
                                                <h5 className="mt-3">Download com Alertas</h5>
                                                <div className="table-responsive" style={{ maxHeight: '35rem', overflowY: 'auto' }}>
                                                    <table className="table table-bordered table-hover w-100">
                                                        <thead>
                                                            <tr>
                                                                <th>Data</th>
                                                                <th>Referência</th>
                                                                {cnpj === '13971933000178' && (
                                                                    <th>Contabilidade</th>
                                                                )}
                                                                <th>Operador</th>
                                                                <th>Empresa</th>
                                                                <th>Avisos</th>
                                                                <th>Download</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredResponse.filter(item => item.str_log && item.str_log.length > 2).length > 0 ? (
                                                                filteredResponse
                                                                    .filter(item => item.str_log && item.str_log.length > 2)
                                                                    .map((item, i) => (
                                                                        <tr key={i} className="table-warning">
                                                                            <td>{convertDateTime(item.d_data)}</td>
                                                                            <td>{item.str_referencia}</td>
                                                                            {cnpj === '13971933000178' && (
                                                                                <td>{item.str_razao}</td>
                                                                            )}
                                                                            <td>{item.str_nome}</td>
                                                                            <td>{item.str_razaosocial}</td>
                                                                            <td>
                                                                                <center>
                                                                                    <button
                                                                                        className="btn btn-danger d-flex justify-content-center align-items-center"
                                                                                        onClick={() => handleShowLog(item.str_log)}
                                                                                    >
                                                                                        <i className="fa-solid fa-warning me-2"></i>Ver Alertas
                                                                                    </button>
                                                                                </center>
                                                                            </td>
                                                                            <td>
                                                                                <center>
                                                                                    {item.str_url === "URL Expirado" ? (
                                                                                        <span>URL Expirado</span>
                                                                                    ) : (
                                                                                        <a
                                                                                            href={item.str_url}
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                            className="btn btn-primary d-flex justify-content-center align-items-center"
                                                                                        >
                                                                                            <i className="fa-solid fa-download me-2"></i>Baixar
                                                                                        </a>
                                                                                    )}
                                                                                </center>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={7} className="text-center">
                                                                        Nenhum registro com alertas encontrado.
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                {/* Tabela sem alertas */}
                                                <h5 className="mt-3">Download sem Alertas</h5>
                                                <div className="table-responsive" style={{ maxHeight: '35rem', overflowY: 'auto' }}>
                                                    <table className="table table-bordered table-hover w-100">
                                                        <thead>
                                                            <tr>
                                                                <th>Data</th>
                                                                <th>Referência</th>
                                                                {cnpj === '13971933000178' && (
                                                                    <th>Contabilidade</th>
                                                                )}
                                                                <th>Operador</th>
                                                                <th>Empresa</th>
                                                                <th>Download</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredResponse.filter(item => !item.str_log || item.str_log.length <= 2).length > 0 ? (
                                                                filteredResponse
                                                                    .filter(item => !item.str_log || item.str_log.length <= 2)
                                                                    .map((item, i) => (
                                                                        <tr key={i} className="table-success">
                                                                            <td>{convertDateTime(item.d_data)}</td>
                                                                            <td>{item.str_referencia}</td>
                                                                            {cnpj === '13971933000178' && (
                                                                                <td>{item.str_razao}</td>
                                                                            )}
                                                                            <td>{item.str_nome}</td>
                                                                            <td>{item.str_razaosocial}</td>
                                                                            <td>
                                                                                <center>
                                                                                    {item.str_url === "URL Expirado" ? (
                                                                                        <span>URL Expirado</span>
                                                                                    ) : (
                                                                                        <a
                                                                                            href={item.str_url}
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                            className="btn btn-primary d-flex justify-content-center align-items-center"
                                                                                        >
                                                                                            <i className="fa-solid fa-download me-2"></i>Baixar
                                                                                        </a>
                                                                                    )}
                                                                                </center>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={6} className="text-center">
                                                                        Nenhum registro sem alertas encontrado.
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default HistoricoDownload;