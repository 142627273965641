import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../components/sidebar/index.jsx";
import TopBar from "../../components/topbar/index.jsx";
import { api, consultaEmpresas } from "../../services/api.jsx";
import Loader from "../../components/loader/index.jsx";
import { AuthContext } from "../../contexts/auth.jsx";
import { formatarCNPJ } from "../../function/formatCNPJ.jsx";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Empresa = () => {

    const { logout } = useContext(AuthContext);
    const Navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        else {
            Navigate("/login");
        }
        consultaEmpresasF().then(() => {
            setLoading(false);
        });

    }, []);

    const consultaEmpresasF = async () => {
        try {
            var response = await consultaEmpresas();
            setResponse(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    const [searchTerm, setSearchTerm] = useState('');

    // Filtrar o array de response com base no termo de busca
    const filteredResponse = response.filter((empresa) => {
        const razaoSocial = empresa.str_razaosocial.toLowerCase();
        const fantasia = empresa.str_fantasia.toLowerCase();
        const search = searchTerm.toLowerCase();

        return razaoSocial.includes(search) || fantasia.includes(search);
    });

    const handleRowClick = (empresaId) => {
        Navigate(`/download/${empresaId}`); // Redirecionar para a rota desejada
    };

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xl-12 col-lg-7">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Empresas vinculadas</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Buscar por razão social ou fantasia..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>CNPJ</th>
                                                            <th>Razão Social</th>
                                                            <th>Fantasia</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredResponse && filteredResponse.length > 0 ? (
                                                            filteredResponse.map((empresa, index) => (
                                                                <tr key={index}
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleRowClick(empresa.str_cnpj)}>
                                                                    <td>{formatarCNPJ(empresa.str_cnpj)}</td>
                                                                    <td>{empresa.str_razaosocial}</td>
                                                                    <td>{empresa.str_fantasia}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="3">Nenhum dado encontrado</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Empresa;