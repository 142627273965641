import { useEffect, useState } from "react";
import './style.css';
import { useLocation } from "react-router-dom";

const SideBar = () => {

    const [larguraTela, setLarguraTela] = useState(window.innerWidth);
    const [padrao, setPadrao] = useState('show');

    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setLarguraTela(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setPadrao('');
        }
        else {
            setPadrao('show');
        }
    }, [larguraTela]);

    const isActive = (path, ignoreLastSegment = false) => {
        let currentPath = location.pathname;

        if (ignoreLastSegment) {
            const pathSegments = currentPath.split('/').filter(Boolean); // Divide e remove segmentos vazios
            currentPath = `/${pathSegments.slice(0, -1).join('/')}`; // Remove o último bloco
        }

        return currentPath === path ? 'active' : '';
    };
    const user = localStorage.getItem('user');
    const userObj = user ? JSON.parse(user) : null;
    const cnpj = userObj ? userObj.str_cnpj : null;

    return (
        <ul className={"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion collapse " + padrao} id="accordionSidebar">
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                <div className="sidebar-brand-icon">
                    <i className="fa-solid fa-book"></i>
                </div>
                <div className="sidebar-brand-text mx-3">EASYDOCS <sup></sup></div>
            </a>
            <hr className="sidebar-divider my-0" />
            <li className={`nav-item ${isActive('/')}`}>
                <a className="nav-link" href="/">
                    <i className="fa-solid fa-dashboard me-2"></i>
                    <span>Dashboard</span></a>
            </li>

            {cnpj !== '13971933000178' && (
            <li className={`nav-item ${isActive('/empresa')}`}>
                <a className="nav-link" href="/empresa">
                    <i className="fa-solid fa-store me-2"></i>
                    <span>Minhas Empresas</span></a>
            </li>
            )}

            {cnpj === '13971933000178' && (
                <>
                    <li className={`nav-item ${isActive('/vinculo-empresa')}`}>
                        <a className="nav-link" href="/vinculo-empresa">
                            <i className="fa-solid fa-sync me-2"></i>
                            <span>Vincular Empresas</span>
                        </a>
                    </li>

                    <li className={`nav-item ${isActive('/contabilidade')}${isActive('/contabilidade/item', true)}`}>
                        <a className="nav-link" href="/contabilidade">
                            <i className="fa-solid fa-industry me-2"></i>
                            <span>Contabilidades</span>
                        </a>
                    </li>
                    <li className={`nav-item ${isActive('/operador')}${isActive('/operador/item', true)}`}>
                        <a className="nav-link" href="/operador">
                            <i className="fa-solid fa-user me-2"></i>
                            <span>Operadores</span>
                        </a>
                    </li>
                </>
            )}

            <li className={`nav-item ${isActive('/download')}`}>
                <a className="nav-link" href="/download">
                    <i className="fa-solid fa-download me-2"></i>
                    <span>Download Arquivos</span></a>
            </li>

            <li className={`nav-item ${isActive('/historico')}`}>
                <a className="nav-link" href="/historico">
                    <i className="fa-solid fa-history me-2"></i>
                    <span>Histórico Download</span></a>
            </li>

            <li className={`nav-item ${isActive('/relatorio/pis-cofins-geral')}`}>
                <a className="nav-link" href="/relatorio/pis-cofins-geral">
                    <i className="fa-solid fa-book me-2"></i>
                    <span>NFe PIS/COFINS GERAL</span></a>
            </li>
            <li className={`nav-item ${isActive('/relatorio/cfe-consolidado')}`}>
                <a className="nav-link" href="/relatorio/cfe-consolidado">
                    <i className="fa-solid fa-book me-2"></i>
                    <span>CFe Consolidado</span></a>
            </li>


            <hr className="sidebar-divider" />
        </ul>
    )

}

export default SideBar;