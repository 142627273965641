import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../../components/sidebar/index.jsx";
import TopBar from "../../components/topbar/index.jsx";
import { api, consultaContabilidade, consultaOperadorId, gravaOperador } from "../../services/api.jsx";
import Loader from "../../components/loader/index.jsx";
import { AuthContext } from "../../contexts/auth.jsx";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const OperadorItem = () => {

    const { logout } = useContext(AuthContext);
    const Navigate = useNavigate();

    const { id = 0 } = useParams();

    const [isLoading, setLoading] = useState(true);
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [ativo, setAtivo] = useState('S');
    const [contabilidade, setContabilidade] = useState([]);
    const [selectContabilidade, setSelectContabilidade] = useState(0);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        else {
            Navigate("/login");
        }

        consultaContabilidadeF();

        if (id !== 0) {
            consultaOperadorIdF(id).then(() => {
                setLoading(false);
            });
        }
        else {
            setLoading(false);
        }

    }, []);

    const consultaOperadorIdF = async (id) => {
        try {
            var response = await consultaOperadorId(id);
            setNome(response.data[0].str_nome);
            setEmail(response.data[0].str_email);
            setAtivo(response.data[0].str_ativo);
            setSelectContabilidade(response.data[0].n_codigocontabilidade);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    const consultaContabilidadeF = async () => {
        try {
            var response = await consultaContabilidade();
            setContabilidade(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (nome === '' || email === '' || selectContabilidade === 0 || (id === 0 && senha === '')) {
            withReactContent(Swal).fire({
                title: "Alerta",
                text: 'Favor preencher campos obrigatórios com (*)',
                icon: "warning"
            })
        }
        else {
            try {
                var response = await gravaOperador(nome, email, senha, ativo, selectContabilidade, id);

                Navigate("/operador");
            }
            catch (err) {

                if (err.response && err.response.status) {
                    if (err.response.status === 401) {
                        logout();
                    }
                    else {
                        withReactContent(Swal).fire({
                            title: "Erro",
                            text: err.response.data,
                            icon: "error"
                        })
                    }
                }
                else {
                    withReactContent(Swal).fire({
                        title: "Erro",
                        text: err.message,
                        icon: "error"
                    })
                }
            }
        }
    }

    const toggleSwitch = () => {
        setAtivo(prevState => (prevState === "S" ? "N" : "S"));
    };

    const handleChange = (event) => {
        setSelectContabilidade(Number(event.target.value)); // Converte para número, se necessário
    };

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">
                                <form onSubmit={handleSubmit}>
                                    <div className="col-xl-12 col-lg-7">
                                        <div className="card shadow mb-4">
                                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                <h6 className="m-0 font-weight-bold text-primary">Operador</h6>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckDefault"
                                                        checked={ativo === "S"}
                                                        onChange={toggleSwitch}
                                                    />
                                                    &nbsp;
                                                    <label className="form-check-label fixed-width-label" htmlFor="flexSwitchCheckDefault">
                                                        {ativo === "S" ? "Ativo" : "Desativado"}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="nome"
                                                                value={nome}
                                                                onChange={(e) => setNome(e.target.value)}
                                                            />
                                                            <label htmlFor="nome">Nome Completo (*)</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                            <label htmlFor="email">E-Mail (*)</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="senha"
                                                                value={senha}
                                                                onChange={(e) => setSenha(e.target.value)}
                                                            />
                                                            <label htmlFor="senha">Senha <i><small>Preencha somente se for criar/alterar</small></i></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        
                                                        <select
                                                            id="contabilidadeSelect"
                                                            value={selectContabilidade}
                                                            onChange={handleChange}
                                                            className="form-select form-select-lg"
                                                        >
                                                            <option selected disabled value={0}>Selecione uma contabilidade (*)</option>
                                                            {contabilidade.map((item) => (
                                                                <option
                                                                    key={item.n_codigocontabilidade}
                                                                    value={item.n_codigocontabilidade}
                                                                >
                                                                    {item.str_razao}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12 d-grid">
                                                        <button className="btn btn-primary btn-lg" type="submit">Salvar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div >
        </>
    )
}

export default OperadorItem;