import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../components/sidebar/index.jsx";
import TopBar from "../../components/topbar/index.jsx";
import { api, consultaEmpresas, consultaEmpresasTodas, gerarDownload } from "../../services/api.jsx";
import Loader from "../../components/loader/index.jsx";
import { AuthContext } from "../../contexts/auth.jsx";
import { formatarCNPJ } from "../../function/formatCNPJ.jsx";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const Download = () => {

    const { logout } = useContext(AuthContext);
    const Navigate = useNavigate();
    const { id = '' } = useParams();

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState({});
    const [selectedEmpresa, setSelectedEmpresa] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [empresa, setEmpresa] = useState([]);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        else {
            Navigate("/login");
        }
        consultaEmpresasF().then(() => {
            setLoading(false);
        });

        if (id !== '') {
            setSelectedEmpresa(id);
        }

        const today = new Date();
        const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1);
        const defaultMonth = previousMonth.toISOString().substring(0, 7); // Formata como YYYY-MM
        setSelectedMonth(defaultMonth);

    }, []);

    const consultaEmpresasF = async () => {
        try {
            const user = localStorage.getItem('user');
            const userObj = user ? JSON.parse(user) : null;
            const cnpj = userObj ? userObj.str_cnpj : null;
            var empresa;

            if (cnpj === '13971933000178') {
                empresa = await consultaEmpresasTodas();
            }
            else {
                empresa = await consultaEmpresas();
            }

            
            setEmpresa(empresa.data);
        }
        catch (err) {
            if (err.empresa.status === 401) {
                logout();
            }
        }

    }

    const handleSelectChange = (e) => {
        setSelectedEmpresa(e.target.value);
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleGerarDownload = async (e) => {
        try {
            setLoading(true);
            setMsg('Gerando arquivos fiscais')
            var response = await gerarDownload(selectedEmpresa, selectedMonth);
            setResponse(response.data);
            setLoading(false);
            setMsg('')
            downloadFile(response.data.url);
        }
        catch (err) {
            setLoading(false);
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    const downloadFile = (url) => {
        const link = document.createElement('a');
        link.href = url ? url : '#';
        link.setAttribute('download', ''); // força o navegador a baixar o arquivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {(isLoading) &&
                <Loader msg={msg} />
            }
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xl-12 col-lg-7">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Download documentos fiscais</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                {response.log && response.log.length > 0 && (
                                                    <div className="alert alert-warning" role="alert">
                                                        <h4>Arquivo gerado com alertas:</h4>
                                                        <ul className="list-group list-group-flush">
                                                            {response.log.map((item, index) => (
                                                                <li key={index} className="list-group-item">
                                                                    {item}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                                <div className="row">
                                                    <div className="col-md-7 col-sm-12">
                                                        <label htmlFor="selectCliente">Selecione a empresa</label>
                                                        <select
                                                            id="selectCliente"
                                                            className="form-control"
                                                            value={selectedEmpresa}
                                                            onChange={handleSelectChange}
                                                        >
                                                            <option value="">Selecione uma empresa</option>
                                                            {empresa.map((empresa, key) => (
                                                                <option key={key} value={empresa.str_cnpj}>
                                                                    {empresa.str_razaosocial} - {formatarCNPJ(empresa.str_cnpj)}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3 col-sm-12">
                                                        <div className="form-group">
                                                            <label htmlFor="selectMonth">Selecione o mês</label>
                                                            <input
                                                                type="month"
                                                                id="selectMonth"
                                                                className="form-control"
                                                                value={selectedMonth}
                                                                onChange={handleMonthChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-sm-12 d-grid">
                                                        <button onClick={(e) => handleGerarDownload()} type="button" className="btn btn-primary">Gerar Download</button>
                                                    </div>
                                                </div>
                                                {response.url && response.url !== '' && (
                                                    <div className="row mt-3">
                                                        <div className="col-12 d-grid gap-2">
                                                            <button onClick={(e) => downloadFile(response.url)} type="button" className="btn btn-success btn-lg">
                                                                Caso o download não tenha iniciado, clique aqui!
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Download;