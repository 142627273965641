export const convertDateTime = (dateTimeString) => {
    if (!dateTimeString) {
        return "";
    }

    // Tente criar um objeto Date a partir da string
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) {
        return ""; // Retorna vazio se a data não for válida
    }

    // Extraia partes da data
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses começam em 0
    const year = date.getFullYear();

    // Extraia partes do horário
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Retorna no formato dd/mm/yyyy hh:ii:ss
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};
