import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../../components/sidebar/index.jsx";
import TopBar from "../../components/topbar/index.jsx";
import { api, consultaCidades, consultaContabilidadeId, gravaContabilidade } from "../../services/api.jsx";
import Loader from "../../components/loader/index.jsx";
import { AuthContext } from "../../contexts/auth.jsx";
import { formatarCNPJ } from "../../function/formatCNPJ.jsx";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Typeahead } from "react-bootstrap-typeahead";

const ContabilidadeItem = () => {

    const { logout } = useContext(AuthContext);
    const Navigate = useNavigate();

    const { id = 0 } = useParams();

    const [isLoading, setLoading] = useState(true);
    const [razao, setRazao] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidades, setCidades] = useState([]);
    const [cidadeSelecionada, setCidadeSelecionada] = useState('');
    const [codCidade, setCodCidade] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        else {
            Navigate("/login");
        }

        consultaCidadesF();

        if (id !== 0) {
            consultaContabilidadeIdF(id).then(() => {
                setLoading(false);
            });
        }
        else {
            setLoading(false);
        }

    }, []);

    const consultaCidadesF = async () => {
        try {
            var response = await consultaCidades();
            setCidades(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    const consultaContabilidadeIdF = async (id) => {
        try {
            var response = await consultaContabilidadeId(id);
            setRazao(response.data[0].str_razao);
            setCnpj(response.data[0].str_cnpj);
            setEndereco(response.data[0].str_endereco);
            setNumero(response.data[0].str_numero);
            setBairro(response.data[0].str_bairro);
            setCodCidade(response.data[0].str_codigocidade);

        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (razao === '' || !cidadeSelecionada) {
            withReactContent(Swal).fire({
                title: "Alerta",
                text: 'Favor preencher campos obrigatórios com (*)',
                icon: "warning"
            })
        }
        else {
            try {
                var response = await gravaContabilidade(razao, cnpj, endereco, numero, bairro, cidadeSelecionada.codigo, id);

                Navigate("/contabilidade");
            }
            catch (err) {

                if (err.response && err.response.status) {
                    if (err.response.status === 401) {
                        logout();
                    }
                    else {
                        withReactContent(Swal).fire({
                            title: "Erro",
                            text: err.response.data,
                            icon: "error"
                        })
                    }
                }
                else {
                    withReactContent(Swal).fire({
                        title: "Erro",
                        text: err.message,
                        icon: "error"
                    })
                }
            }
        }
    }

    useEffect(() => {
        // Encontra a cidade que corresponde ao código fornecido como padrão
        const cidadeDefault = cidades.find(cidade => cidade.codigo === codCidade);
        if (cidadeDefault) {
            setCidadeSelecionada(cidadeDefault);
        }
    }, [codCidade, cidades]);

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">
                                <form onSubmit={handleSubmit}>
                                    <div className="col-xl-12 col-lg-7">
                                        <div className="card shadow mb-4">
                                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                <h6 className="m-0 font-weight-bold text-primary">Contabilidade</h6>
                                                {/* <a className="btn btn-success" href="/contabilidade/">💾 Salvar</a> */}
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="razao"
                                                                value={razao}
                                                                onChange={(e) => setRazao(e.target.value)}
                                                            />
                                                            <label htmlFor="razao">Razão social (*)</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="cnpj"
                                                                value={cnpj}
                                                                onChange={(e) => setCnpj(e.target.value)}
                                                            />
                                                            <label htmlFor="cnpj">CNPJ</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="endereco"
                                                                value={endereco}
                                                                onChange={(e) => setEndereco(e.target.value)}
                                                            />
                                                            <label htmlFor="endereco">Endereço</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-2">
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="numero"
                                                                value={numero}
                                                                onChange={(e) => setNumero(e.target.value)}
                                                            />
                                                            <label htmlFor="numero">N°</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="form-floating mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="bairro"
                                                                value={bairro}
                                                                onChange={(e) => setBairro(e.target.value)}
                                                            />
                                                            <label htmlFor="bairro">Bairro</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12">
                                                        <Typeahead
                                                            id="cidade-typeahead"
                                                            labelKey="nome"
                                                            onChange={(selected) => {
                                                                setCidadeSelecionada(selected.length > 0 ? selected[0] : null);
                                                            }}
                                                            options={cidades}
                                                            placeholder="Cidade (*)"
                                                            selected={cidadeSelecionada ? [cidadeSelecionada] : []} // `selected` espera um array, use `null` para "não selecionado"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12 d-grid">
                                                        <button className="btn btn-primary btn-lg" type="submit">Salvar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div >
        </>
    )
}

export default ContabilidadeItem;