/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, createSession } from '../services/api.jsx';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        try {
            api.defaults.headers.Authorization = `Basic dGVzdHNlcnZlcjp0ZXN0c2VydmVy`;
            const recoveredUser = localStorage.getItem('user');
            const token = localStorage.getItem('token');

            if (recoveredUser) {
                setUser(JSON.parse(recoveredUser));
                api.defaults.headers.Authorization = `Bearer ${token}`;
            }
        } catch (error) {
            logout();
        }

        setLoading(false);
    }, []);

    const login = async (email, password) => {
        try {
            const response = await createSession(email, password);

            if (response.data.user) {
                const loggedUser = response.data.user;
                const token = response.data.token;

                localStorage.setItem('user', JSON.stringify(loggedUser));
                localStorage.setItem('token', token);

                api.defaults.headers.Authorization = `Bearer ${token}`;

                setUser(loggedUser);

                navigate("/dashboard");
            }
        }
        catch (err) {
            setError(err.response.data);
        }
    }

    const logout = () => {
        setUser(null);
        setError("");
        api.defaults.headers.Authorization = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        api.defaults.headers.Authorization = `Basic dGVzdHNlcnZlcjp0ZXN0c2VydmVy`;
        navigate("/login");
    }

    return (
        <AuthContext.Provider value={{ authenticated: !!user, user, loading, error, login, logout, setLoading, setError }}>
            {children}
        </AuthContext.Provider>
    );
}

