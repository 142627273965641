export const convertDate = (dateString) => {
    if (!dateString) {
        return "";
    }

    // Divida a string de data em partes [ano, mês, dia]
    const parts = dateString.split('-');
    if (parts.length !== 3) {
        return "";
    }

    const [year, month, day] = parts;

    // Verificação adicional para garantir que o ano, mês e dia são números válidos
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
        return "";
    }

    // Retorna a data formatada no padrão dd/mm/yyyy
    return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
};