import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../../components/sidebar/index.jsx";
import TopBar from "../../../components/topbar/index.jsx";
import { api, consultaEmpresas, PisCofinsGeral } from "../../../services/api.jsx";
import Loader from "../../../components/loader/index.jsx";
import { AuthContext } from "../../../contexts/auth.jsx";
import { formatarCNPJ } from "../../../function/formatCNPJ.jsx";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { convertReal } from "../../../function/convertReal.jsx";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const PISCOFINGeral = () => {

    const { logout } = useContext(AuthContext);
    const Navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState([]);
    const [selectedEmpresa, setSelectedEmpresa] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [empresa, setEmpresa] = useState([]);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        else {
            Navigate("/login");
        }

        consultaEmpresasF().then(() => {
            setLoading(false);
        });

        const today = new Date();
        const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1);
        const defaultMonth = previousMonth.toISOString().substring(0, 7); // Formata como YYYY-MM
        setSelectedMonth(defaultMonth);

    }, []);

    const consultaEmpresasF = async () => {
        try {
            var empresa = await consultaEmpresas();
            setEmpresa(empresa.data);
        }
        catch (err) {
            if (err.empresa.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }

    }

    const handleGerarRelatorio = async (e) => {
        try {
            setLoading(true);
            setMsg('Gerando relatório')
            var response = await PisCofinsGeral(selectedEmpresa, selectedMonth);
            setResponse(response.data);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    const handleSelectChange = (e) => {
        setSelectedEmpresa(e.target.value);
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleExport = () => {
        const data = response.flatMap(item =>
            item.piscofins.flatMap(pisCofinsItem => pisCofinsItem.produtos)
        );

        // Converte os dados para um formato compatível com Excel (array de arrays ou objetos)
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Gera um buffer do arquivo e salva
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'dados.xlsx');
    };

    return (
        <>
            {(isLoading) &&
                <Loader msg={msg} />
            }
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xl-12 col-lg-7">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Relatório NFe PIS/COFINS Geral</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-7 col-sm-12">
                                                    <label htmlFor="selectCliente">Selecione a empresa</label>
                                                    <select
                                                        id="selectCliente"
                                                        className="form-control"
                                                        value={selectedEmpresa}
                                                        onChange={handleSelectChange}
                                                    >
                                                        <option value="">Selecione uma empresa</option>
                                                        {empresa.map((empresa, key) => (
                                                            <option key={key} value={empresa.str_cnpj}>
                                                                {empresa.str_razaosocial} - {formatarCNPJ(empresa.str_cnpj)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-3 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="selectMonth">Selecione o mês</label>
                                                        <input
                                                            type="month"
                                                            id="selectMonth"
                                                            className="form-control"
                                                            value={selectedMonth}
                                                            onChange={handleMonthChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-sm-12 d-grid">
                                                    <button onClick={(e) => handleGerarRelatorio()} type="button" className="btn btn-primary">Gerar Relatório</button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <button onClick={handleExport} type="button" className="btn btn-success">
                                                    <i className="fa-solid fa-table me-2"></i>Excel
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="accordion" id="accordionCFOP">
                                                    {response.slice().reverse().map((cfop, cfopIndex) => (
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header">
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={'#' + cfop.STR_CATEGORIA_CFOP} aria-expanded="true" aria-controls={cfop.STR_CATEGORIA_CFOP}>
                                                                    <table className="w-100">
                                                                        <thead>
                                                                            <tr>
                                                                                <th colSpan={5}><h4>{cfop.STR_TCATEGORIA_CFOP}</h4></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Total dos produtos</td>
                                                                                <td>Pis Base</td>
                                                                                <td>Pis Valor</td>
                                                                                <td>Cofins Base</td>
                                                                                <td>Cofins Valor</td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>{convertReal(cfop.TOTALLIQUIDO)}</th>
                                                                                <th>{convertReal(cfop.PIS_BASE_CALCULO)}</th>
                                                                                <th>{convertReal(cfop.PIS_VALOR_PIS)}</th>
                                                                                <th>{convertReal(cfop.COFINS_BASE_CALCULO)}</th>
                                                                                <th>{convertReal(cfop.COFINS_VALOR_COFINS)}</th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </button>
                                                            </h2>
                                                            <div id={cfop.STR_CATEGORIA_CFOP} className="accordion-collapse collapse show" data-bs-parent={'#' + cfop.STR_CATEGORIA_CFOP}>
                                                                <div className="accordion-body">

                                                                    <div className="accordion" id="accordionCFOP">
                                                                        {cfop.piscofins.map((pc, cfopIndex) => (
                                                                            <div className="accordion-item">
                                                                                <h2 className="accordion-header">
                                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={'#' + cfop.STR_CATEGORIA_CFOP + pc.PIS_SITUACAO_TRIBUTARIA} aria-expanded="true" aria-controls={cfop.STR_CATEGORIA_CFOP + pc.PIS_SITUACAO_TRIBUTARIA}>
                                                                                        <table className="w-100">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>PIS {pc.PIS_SITUACAO_TRIBUTARIA} — <strong>{convertReal(pc.PIS_ALICOTA_PERCENTUAL)}%</strong></td>
                                                                                                    <td>Total dos produtos</td>
                                                                                                    <td>Total BC PIS : <strong>{convertReal(pc.PIS_BASE_CALCULO)}</strong></td>
                                                                                                    <td>Valor do PIS : <strong>{convertReal(pc.PIS_VALOR_PIS)}</strong></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>COFINS {pc.COFINS_SITUACAO_TRIBUTARIA} — <strong>{convertReal(pc.COFINS_ALICOTA_PERCENTUAL)}%</strong></td>
                                                                                                    <td><strong>{convertReal(pc.TOTALLIQUIDO)}</strong></td>
                                                                                                    <td>Total BC COFINS : <strong>{convertReal(pc.COFINS_BASE_CALCULO)}</strong></td>
                                                                                                    <td>Valor do COFINS : <strong>{convertReal(pc.COFINS_VALOR_COFINS)}</strong></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </button>
                                                                                </h2>
                                                                                <div id={cfop.STR_CATEGORIA_CFOP + pc.PIS_SITUACAO_TRIBUTARIA} className="accordion-collapse collapse show" data-bs-parent={'#' + cfop.STR_CATEGORIA_CFOP + pc.PIS_SITUACAO_TRIBUTARIA}>
                                                                                    <div className="accordion-body p-0">
                                                                                        <table style={{ maxHeight: '600px' }} className="table table-bordered m-0 table-sm table-responsive">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th style={{ minWidth: '150px' }}>Código produto</th>
                                                                                                    <th>Descrição</th>
                                                                                                    <th>Quantidade</th>
                                                                                                    <th>NCM</th>
                                                                                                    <th>CFOP</th>
                                                                                                    <th>Total</th>
                                                                                                    <th>PIS</th>
                                                                                                    <th>BC PIS</th>
                                                                                                    <th>Aliq PIS</th>
                                                                                                    <th>Valor PIS</th>
                                                                                                    <th>COFINS</th>
                                                                                                    <th>BC COFINS</th>
                                                                                                    <th>Aliq COFINS</th>
                                                                                                    <th>Valor COFINS</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {pc.produtos.map((prod, cfopIndex) => (
                                                                                                    <tr>
                                                                                                        <td>{prod.dados_codigo}</td>
                                                                                                        <td>{prod.dados_descricao}</td>
                                                                                                        <td>{prod.cofins_qdade_vendida}</td>
                                                                                                        <td>{prod.dados_ncm}</td>
                                                                                                        <td>{prod.dados_cfop}</td>
                                                                                                        <td>{convertReal(prod.totalliquido)}</td>
                                                                                                        <td>{prod.pis_situacao_tributaria}</td>
                                                                                                        <td>{convertReal(prod.pis_base_calculo)}</td>
                                                                                                        <td>{convertReal(prod.pis_alicota_valor)}</td>
                                                                                                        <td>{convertReal(prod.pis_valor_pis)}</td>
                                                                                                        <td>{prod.cofins_situacao_tributaria}</td>
                                                                                                        <td>{convertReal(prod.cofins_base_calculo)}</td>
                                                                                                        <td>{convertReal(prod.cofins_alicota_valor)}</td>
                                                                                                        <td>{convertReal(prod.cofins_valor_cofins)}</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default PISCOFINGeral;