import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from "./contexts/auth.jsx";
import Empresa from './pages/empresa/index.jsx';
import Login from './pages/login/index.jsx';
import Download from './pages/download/index.jsx';
import VinculoEmpresa from './pages/vinculo-empresa/index.jsx';
import Contabilidade from './pages/contabilidade/index.jsx';
import ContabilidadeItem from './pages/contabilidade-item/index.jsx';
import Operador from './pages/operador/index.jsx';
import OperadorItem from './pages/operador-item/index.jsx';
import PISCOFINGeral from './pages/relatorio/pis-cofins-geral/index.jsx';
import CFeConsolidado from './pages/relatorio/cfe-consolidado/index.jsx';
import HistoricoDownload from './pages/relatorio/historico/index.jsx';
import DashBoard from './pages/dashboard/index.jsx';

function Rotas() {

    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);
        if (loading) {
            return <p>Carregando....</p>;
        }
        if (!authenticated) {
            return <Navigate to="/login" />;
        }
        return children;
    }

    const Public = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);
        if (loading) {
            return <p>Carregando....</p>;
        }
        if (authenticated) {
            return <Navigate to="/" />;
        }
        return children;
    }

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route exact path='/' element={<Private><DashBoard /></Private>} />
                    <Route exact path='/empresa' element={<Private><Empresa /></Private>} />
                    <Route exact path='/vinculo-empresa' element={<Private><VinculoEmpresa /></Private>} />
                    <Route exact path='/historico' element={<Private><HistoricoDownload /></Private>} />
                    <Route exact path='/download' element={<Private><Download /></Private>} />
                    <Route exact path='/download/:id' element={<Private><Download /></Private>} />
                    <Route exact path='/contabilidade' element={<Private><Contabilidade /></Private>} />
                    <Route exact path='/relatorio/pis-cofins-geral' element={<Private><PISCOFINGeral /></Private>} />
                    <Route exact path='/relatorio/cfe-consolidado' element={<Private><CFeConsolidado /></Private>} />
                    <Route exact path='/operador' element={<Private><Operador /></Private>} />
                    <Route exact path='/operador/item/:id' element={<Private><OperadorItem /></Private>} />
                    <Route exact path='/operador/item' element={<Private><OperadorItem /></Private>} />
                    <Route exact path='/contabilidade/item/:id' element={<Private><ContabilidadeItem /></Private>} />
                    <Route exact path='/contabilidade/item' element={<Private><ContabilidadeItem /></Private>} />

                    <Route path="*" element={<Public><Login /></Public>} />
                    <Route path='/login' element={<Public><Login /></Public>} />
                    {/* <Route path='/cadastro' element={<Public><Cadastro /></Public>} /> */}
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default Rotas;