import React from 'react';

const SelectContabilidade = ({ value, options, onChange }) => {
    return (
        <select className="form-select form-select-sm" value={value !== null ? value : ""} onChange={onChange}>
            <option value="" disabled>
                Selecione...
            </option>
            {options.map((opcao) => (
                <option key={opcao.n_codigocontabilidade} value={opcao.n_codigocontabilidade}>
                    {opcao.str_razao}
                </option>
            ))}
        </select>
    );
};

export default SelectContabilidade;
