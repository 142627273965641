import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';

const TopBar = () => {

    const { logout, user } = useContext(AuthContext);
    const handlerLogout = () => {
        logout();
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3"
                data-bs-toggle="collapse" data-bs-target="#accordionSidebar" aria-expanded="true" aria-controls="accordionSidebar">
                <i className="fa fa-bars"></i>
            </button>
            <div className="navbar-collapse collapse col-lg-12 col-9" id="navbarNav" style={{ display: 'block' }}>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown no-arrow hide-user">
                        <a className="nav-link dropdown-toggle" href="#userDropdown" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="userDropdown">
                            <span className="mr-2 d-lg-inline text-gray-600 small">{user.str_nome.split(" ").slice(0, 2).join(" ")}</span>
                            <img className="img-profile rounded-circle" src="https://cdn-icons-png.flaticon.com/512/3177/3177440.png" alt='Usuario'/>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" id="userDropdown">
                            <a onClick={handlerLogout} className="dropdown-item" href={handlerLogout ? handlerLogout : '#'} data-toggle="modal" data-target="#logoutModal">
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Sair
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default TopBar;