import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../components/sidebar/index.jsx";
import TopBar from "../../components/topbar/index.jsx";
import { api, consultaEmpresas, dashboard, dashboardFaturamento, dashboardQuantidade } from "../../services/api.jsx";
import Loader from "../../components/loader/index.jsx";
import { AuthContext } from "../../contexts/auth.jsx";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import './style.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatarCNPJ } from "../../function/formatCNPJ.jsx";


ChartJS.register(
    CategoryScale,
    LineElement,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const DashBoard = () => {

    const { logout } = useContext(AuthContext);
    const Navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState({ empresasCadastradas: 0, empresasPendentesTotal: 0, empresasPendentesLista: [], downloadsRealizados: 0 });
    const [quantidade, setQuantidade] = useState({ ano: [], mes: [], total: [] });
    const [faturamento, setFaturamento] = useState({ ano: [], mes: [], total: [] });
    const [empresa, setEmpresa] = useState([]);
    const [selectedEmpresaQuantidade, setSelectedEmpresaQuantidade] = useState("");
    const [selectedEmpresaFaturamento, setSelectedEmpresaFaturamento] = useState("");

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        else {
            Navigate("/login");
        }

        // dashBoardQuantidadeF();
        loadInicial().then(() => {
            setLoading(false);
        });

    }, []);

    const loadInicial = async () => {
        await dashboardF();
        await consultaEmpresasF();
    }

    const dashBoardQuantidadeF = async (cnpj) => {
        try {
            var response = await dashboardQuantidade(cnpj);
            setQuantidade(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    const dashBoardFaturamentoF = async (cnpj) => {
        try {
            var response = await dashboardFaturamento(cnpj);
            setFaturamento(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    const dashboardF = async () => {
        try {
            var response = await dashboard();
            setResponse(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    const consultaEmpresasF = async () => {
        try {
            var response = await consultaEmpresas();
            setEmpresa(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    const handleSelectChangeQuantidade = async (e) => {
        setSelectedEmpresaQuantidade(e.target.value);

        setLoading(true);
        await dashBoardQuantidadeF(e.target.value);
        setLoading(false);
    };

    const handleSelectChangeFaturamento = async (e) => {
        setSelectedEmpresaFaturamento(e.target.value);

        setLoading(true);
        await dashBoardFaturamentoF(e.target.value);
        setLoading(false);
    };

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="block-header">
                                <h6>DASHBOARD</h6>
                            </div>
                            <div className="row mb-3">

                                <div className="col-xl-4 col-lg-6">
                                    <div className="card card-stats mb-4 mb-xl-2">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h5 className="card-title text-uppercase text-muted mb-2">Empresas cadastradas</h5>
                                                    <span className="h2 font-weight-bold mb-0">{response.empresasCadastradas}</span>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                        <i className="fas fa-shop"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <p className="mt-3 mb-0 text-muted text-sm">
                                                <span className="text-success mr-2"><i className="fa fa-arrow-up"></i> 3.48%</span>
                                                <span className="text-nowrap">Since last month</span>
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className="card card-stats mb-4 mb-xl-2">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h5 className="card-title text-uppercase text-muted mb-2">Qtd sincronização pendente</h5>
                                                    <span className="h2 font-weight-bold mb-0">{response.empresasPendentesTotal}</span>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                        <i className="fas fa-rotate"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-stats mb-4 mb-xl-2">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h5 className="card-title text-uppercase text-muted mb-2">Downloads este mês</h5>
                                                    <span className="h2 font-weight-bold mb-0">{response.downloadsRealizados}</span>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                                        <i className="fas fa-download"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-6">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body table-responsive" style={{ height: '262px' }}>

                                            <table className="table table-hover">
                                                <tbody>
                                                    {response.empresasPendentesLista.map((item) => (
                                                        <tr key={item.idcloud}>
                                                            <td>{item.str_razaosocial}</td>
                                                            <td>
                                                                <button
                                                                    className={`btn btn-sm ${item.dias_passados > 30 ? "btn-danger" : "btn-warning"
                                                                        }`}
                                                                >
                                                                    {item.dias_passados} dias
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix mb-3">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <select
                                                        id="selectCliente"
                                                        className="form-control"
                                                        value={selectedEmpresaFaturamento}
                                                        onChange={handleSelectChangeFaturamento}
                                                    >
                                                        <option value="">Selecione uma empresa</option>
                                                        {empresa.map((empresa, key) => (
                                                            <option key={key} value={empresa.str_cnpj}>
                                                                {empresa.str_razaosocial} - {formatarCNPJ(empresa.str_cnpj)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            {faturamento.total.length > 0 ? (
                                                <div className="row" style={{ height: '45vh' }}>
                                                    <Line
                                                        options={{
                                                            maintainAspectRatio: false,
                                                            responsive: true,
                                                            plugins: {
                                                                filler: {
                                                                    propagate: false,
                                                                },
                                                                title: {
                                                                    display: true,
                                                                    text: 'Faturamento'
                                                                }
                                                            },
                                                            interaction: {
                                                                intersect: false,
                                                            }
                                                        }}
                                                        data={{
                                                            labels: faturamento.mes.map((mes, index) => `${mes}/${faturamento.ano[index]}`),
                                                            datasets: [
                                                                {
                                                                    label: 'Faturamento',
                                                                    data: faturamento.total,
                                                                    borderColor: 'rgba(255, 0, 0, 1)',
                                                                    backgroundColor: 'rgba(255, 0, 0, 0.5)',
                                                                    fill: false
                                                                }
                                                            ]
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className="row"
                                                    style={{
                                                        height: '15vh',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                        fontSize: '24px', // Tamanho grande do texto
                                                        fontWeight: 'bold' // Para deixar o texto em negrito (opcional)
                                                    }}
                                                >
                                                    Selecione a empresa acima para gerar o gráfico
                                                </div>


                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix mb-3">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <select
                                                        id="selectCliente"
                                                        className="form-control"
                                                        value={selectedEmpresaQuantidade}
                                                        onChange={handleSelectChangeQuantidade}
                                                    >
                                                        <option value="">Selecione uma empresa</option>
                                                        {empresa.map((empresa, key) => (
                                                            <option key={key} value={empresa.str_cnpj}>
                                                                {empresa.str_razaosocial} - {formatarCNPJ(empresa.str_cnpj)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            {quantidade.total.length > 0 ? (
                                                <div className="row" style={{ height: '45vh' }}>
                                                    <Line
                                                        options={{
                                                            maintainAspectRatio: false,
                                                            responsive: true,
                                                            plugins: {
                                                                filler: {
                                                                    propagate: false,
                                                                },
                                                                title: {
                                                                    display: true,
                                                                    text: 'Documentos emitidos'
                                                                }
                                                            },
                                                            interaction: {
                                                                intersect: false,
                                                            }
                                                        }}
                                                        data={{
                                                            labels: quantidade.mes.map((mes, index) => `${mes}/${quantidade.ano[index]}`),
                                                            datasets: [
                                                                {
                                                                    label: 'Quantidade',
                                                                    data: quantidade.total,
                                                                    borderColor: 'rgba(0, 0, 255, 1)',
                                                                    backgroundColor: 'rgba(0, 0, 255, 0.5)',
                                                                    fill: false
                                                                }
                                                            ]
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className="row"
                                                    style={{
                                                        height: '15vh',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                        fontSize: '24px', // Tamanho grande do texto
                                                        fontWeight: 'bold' // Para deixar o texto em negrito (opcional)
                                                    }}
                                                >
                                                    Selecione a empresa acima para gerar o gráfico
                                                </div>


                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashBoard;