import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../components/sidebar/index.jsx";
import TopBar from "../../components/topbar/index.jsx";
import { api, consultaContabilidade, consultaEmpresas } from "../../services/api.jsx";
import Loader from "../../components/loader/index.jsx";
import { AuthContext } from "../../contexts/auth.jsx";
import { formatarCNPJ } from "../../function/formatCNPJ.jsx";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Contabilidade = () => {

    const { logout } = useContext(AuthContext);
    const Navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        else {
            Navigate("/login");
        }
        consultaContabilidadeF().then(() => {
            setLoading(false);
        });

    }, []);

    const consultaContabilidadeF = async () => {
        try {
            var response = await consultaContabilidade();
            setResponse(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
            else {
                withReactContent(Swal).fire({
                    title: "Erro",
                    text: err.response.data,
                    icon: "error"
                })
            }
        }
    }

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xl-12 col-lg-7">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Contabilidades cadastradas</h6>
                                            <a className="btn btn-success" href="/contabilidade/item">+ Cadastrar contabilidade</a>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>CNPJ</th>
                                                        <th>Razão Social</th>
                                                        <th>Endereço</th>
                                                        <th>Cidade-UF</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {response && response.length > 0 ? (
                                                        response.map((empresa, index) => (
                                                            <tr key={index}>
                                                                <td>{empresa.str_cnpj ? formatarCNPJ(empresa.str_cnpj) : ''}</td>
                                                                <td>{empresa.str_razao || ''}</td>
                                                                <td>
                                                                    {[
                                                                        empresa.str_endereco || '',
                                                                        empresa.str_numero || '',
                                                                        empresa.str_bairro || ''
                                                                    ]
                                                                        .filter(Boolean)
                                                                        .join(', ')}
                                                                </td>
                                                                <td>
                                                                    {[
                                                                        empresa.str_cidade || '',
                                                                        empresa.str_estado || ''
                                                                    ]
                                                                        .filter(Boolean)
                                                                        .join('-')}
                                                                </td>
                                                                <td><a href={"contabilidade/item/" + empresa.n_codigocontabilidade} className="btn btn-info">Editar</a></td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4">Nenhum dado disponível</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Contabilidade;