import axios from "axios";

export const api = axios.create({
    // timeout: 300000,
    // baseURL: 'http://localhost:9008',
    //baseURL : 'http://200.150.203.85:9005/',
    baseURL : 'https://apidocs.easysoftsistemas.com.br',
});

export const createSession = async (email, senha) => {
    return api.post('/login', { email, senha });
}

export const consultaCidades = async () => {
    return api.get('/cidade');
}

export const consultaEmpresas = async () => {
    return api.get('/empresa');
}

export const consultaContabilidadeId = async (id) => {
    return api.get('/contabilidade/' + id);
}

export const dashboard = async () => {
    return api.get('/dashboard/');
}

export const dashboardQuantidade = async (cnpj) => {
    return api.get('/dashboard/quantidade/', { params: { cnpj } });
}

export const dashboardFaturamento = async (cnpj) => {
    return api.get('/dashboard/faturamento/', { params: { cnpj } });
}

export const consultaOperadorId = async (id) => {
    return api.get('/operador/' + id);
}

export const consultaOperador = async () => {
    return api.get('/operador');
}

export const consultaEmpresasTodas = async () => {
    return api.get('/empresa/all');
}

export const gerarDownload = async (cnpj, mes) => {
    return api.get('/download', { params: { cnpj, mes } });
}

export const PisCofinsGeral = async (cnpj, mes) => {
    return api.get('/nfe-pis-cofins/', { params: { cnpj, mes } });
}

export const CFeConsolidadoA = async (cnpj, mes) => {
    return api.get('/cfe-consolidado/', { params: { cnpj, mes } });
}

export const consultaContabilidade = async (cnpj, mes) => {
    return api.get('/contabilidade', { params: { cnpj, mes } });
}

export const consultaHistorico = async (mes) => {
    return api.get('/historico', { params: { mes } });
}

export const gravaContabilidade = async (razao, cnpj, endereco, numero, bairro, cidade, codigo) => {
    return api.post('/contabilidade', { razao, cnpj, endereco, numero, bairro, cidade, codigo });
}

export const gravaOperador = async (nome, email, senha, ativo, codigocontabilidade, codigo) => {
    return api.post('/operador', { nome, email, senha, ativo, codigocontabilidade, codigo });
}

export const vinculaContabilidade = async (idcloud, n_codigocontabilidade) => {
    return api.put('/empresa/contabilidade', { idcloud, n_codigocontabilidade });
}

